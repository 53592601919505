.closeBtn {
  position: absolute !important;
  top: 10px;
  right: 10px;
}

.deleteBtn {
  position: absolute !important;
  left: 10px;
  bottom: 10px;
}

.clearMessageItem {
  margin-left: 10px !important;
}

.downloadBtn {
  margin-left: 10px !important;
}