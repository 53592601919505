.metismenu {
  background: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  overflow: hidden;
  position: relative
}

.metismenu>.metismenu-container>.metismenu-item>.metismenu-link {
  line-height: 2.5em
}

.metismenu>.metismenu-container>.metismenu-item>.metismenu-link .metismenu-state-icon {
  line-height: 2.5em
}

.metismenu::after {
  /*box-shadow: 0 0 .4em rgba(0, 0, 0, .5) inset;*/
  /*-webkit-box-shadow: 0 0 .4em rgba(0, 0, 0, .5) inset;*/
  content: " ";
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0
}

.metismenu-container,
.metismenu-item {
  margin: 0;
  padding: 0
}

.metismenu-container {
  list-style: none
}

.metismenu-container .metismenu-container {
  /*box-shadow: 0 .3em .3em -.3em #0c0d0e inset, 0 -.3em .3em -.3em #0c0d0e inset;*/
  -webkit-box-shadow: 0 1px 1px -1px #0c0d0e inset, 0 -1px 1px -1px #0c0d0e inset;
  /*border-bottom: 1px solid rgba(0,0,0,.1);*/
  transition: padding .3s;
  -webkit-transition: padding .3s;
  background: rgba(255, 255, 255, .05)
}

.metismenu-container .metismenu-container .metismenu-item>.metismenu-link {
  height: 0;
  overflow: hidden
}

.metismenu-container .metismenu-container .metismenu-link {
  padding-left: 1em
}

.metismenu-container .metismenu-container .metismenu-container .metismenu-link {
  padding-left: 2em
}

.metismenu-container.visible {
  padding: .5em 0
}

.metismenu-container.visible>.metismenu-item>.metismenu-link {
  height: 2.5em
}

.metismenu-link {
  /*color: #ddd;*/
  color: rgba(0, 0, 0, 0.8);
  /*text-shadow: 0 -1px 0 rgba(0, 0, 0, .5);*/
  /*-webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, .5);*/
  transition: height .3s, color .3s, background-color .3s;
  -webkit-transition: height .3s, color .3s, background-color .3s;
  display: block;
  line-height: 2.5em;
  text-decoration: none
}

.metismenu-link:hover {
  background: rgba(66, 33, 16, .66);
  /*color: #f7f7f7*/
  color: rgba(255, 255, 255, 1);
}

.metismenu-link.active {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
  -webkit-text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
  background: #422110;
  color: #fff;
}

.metismenu-link.has-active-child {
  color: #422110
}

I.metismenu-icon {
  text-align: center;
  width: 15px;
  /*display: none;*/
}

I.metismenu-state-icon {
  transition: transform .3s;
  -webkit-transition: transform .3s;
  float: right;
  line-height: 2.5em;
  text-align: center;
  width: 3em
}

I.metismenu-state-icon.rotate-minus-90 {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg)
}

.metismenu {
  position: relative;
  width: 240px;
  display: block;
  /*bottom: 0;*/
  /*left: 0;*/
  /*top: 0;*/
}